import React from 'react';
import styled from '@emotion/styled';
import ScrollUpButton from 'react-scroll-up-button';
import Arrow from './Icons';
import './scrollToTop.css';

const ButtonWrapper = styled.div`
  height: 48px;
  width: 48px;
  border-radius: 50%;
  background-color: ${p => p.theme.scrollToTopColor};
  display: flex;
  align-items: center;
  transition: transform 0.05s linear;
  &:hover {
    cursor: pointer;
    background-color: #bd10e0;
    transform: scale(1.05);
  }
  &::-moz-focus-inner {
    border: 0;
  }
  &:focus {
    outline: 2px dotted #bd10e0;
  }
  svg {
    outline: none;
    margin: 0 auto;
    display: block;
  }
`;

// Screen reader
const SROnly = styled.span`
  height: 1px;
  overflow: hidden;
  position: absolute;
  width: 1px;
`;

const ScrollToTopButton = () => (
  <ScrollUpButton
    ContainerClassName="Container"
    TransitionClassName="Transition"
  >
    <ButtonWrapper>
      <SROnly>Back to the top</SROnly>
      <Arrow color="white" />
    </ButtonWrapper>
  </ScrollUpButton>
);

export default ScrollToTopButton;
