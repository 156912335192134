const base = {
  name: 'Dekor - Stefanović', // Site title.
  url: 'https://dekor-stefanovic.rs' // Domain of your website. No trailing slash!
};

module.exports = {
  /* meta tags */
  siteTitle: `${base.name} | Završni radovi u gradjevinarstvu`,
  siteTitleAlt: base.name, // Title for JSONLD.
  siteTitleTemplate: `%s | ${base.name}`, // Useful when you want titles to inherit from a template
  siteDescription: `${
    base.name
  } je tim, specijalizovan za završne radove u gradjevinarstvu. Nudimo usluge montaže elastičnih plafona od vrhunskih PVC materijala, sve vrste gipsarskih i molersko tapetarskih radova.`,
  siteHeadline: 'Završni radovi u gradjevinarstvu', // Headline for schema.org JSONLD

  /* url */
  siteUrl: base.url, // Domain of your site. No trailing slash!
  pathPrefix: '/', // Prefixes all links. For cases when deployed to example.github.io/gatsby-example/.

  siteLogo: '/logos/logo.png', // Logo used for SEO.
  siteLanguage: 'sr_Latn', // Language Tag on <html> element

  /* social */
  twitter: '@dekor-stefanovic', // Twitter Username
  facebook: 'dekor-stefanovic', // Facebook Site Name
  ogLanguage: 'sr_Latn', // Facebook Language

  gTagID: '', // googleAnalyticsID gtag tracking ID.
  fbAppID: '', // FB Application ID for using app insights

  /* manifest */
  manifestName: base.name,
  manifestShortName: 'Dekor', // shortname for manifest. MUST be shorter than 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#293541', // Used for setting manifest background color.
  manifestThemeColor: '#bd10e0', // Used for setting manifest and progress theme colors.
  manifestDisplay: 'standalone',
  manifestIcon: 'src/img/icon.png',

  /* author */
  author: 'Darkwood', // Author for schemaORGJSONLD
  authorLink: 'https://darkwood.dev',

  /* copyright */
  copyright: '© 2019 DEKOR - Stefanović', // Copyright string for the footer of the website and RSS feed.

  /* menu links */
  menu: [
    {
      to: '/',
      label: 'Početna'
    },
    {
      to: '/about',
      label: 'O nama'
    },
    {
      to: '/services',
      label: 'Usluge'
    },
    {
      to: '/gallery',
      label: 'Galerija'
    },
    {
      to: '/contact',
      label: 'Kontakt'
    }
  ]
};
